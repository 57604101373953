import styled from "@emotion/styled";

export const StyledButton = styled.button`
  display: block;
  text-align: center;
  margin: 0 auto;
  margin-top: 15px;
  background-color: rgb(0, 116, 166);
  color: #fff;
  padding: 12.5px 25px;
  border: none;
  border-radius: 25px;
  font-weight: 600;
  font-family: "Raleway", sans-serif;
  transition: 0.5s;
  &:hover {
    background-color: #2a4671;
    transform: scale(1.025);
  }
  &.color-light {
    background-color: #fff;
    color: rgb(0, 116, 166);
  }
`;
