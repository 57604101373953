import styled from "@emotion/styled";

export const Section = styled.section`
  position: relative;
  background-color: #fff;
`;

export const Container = styled.div`
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
  &.dark {
    background-color: #e1ded5;
    z-index: 1;
  }
  &.light {
    background-color: #f5f5ee;
  }
  &.margin-top {
    margin-top: 75px;
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: #e1ded5;
  }
`;

export const ContentWrapper = styled.div`
  max-width: 1300px;
  position: relative;
  margin: 0 auto;
  padding: 0px 20px;
  &.header {
    max-width: 800px;
  }
  &.align-center {
    text-align: center;
  }
`;

export const BackgroundIllustration = styled.img`
  position: absolute;
  bottom: 0px;
  &.left {
    left: 10-px;
  }
  &.right {
    right: -10px;
  }
`;

export const SectionBorder = styled.img`
  width: 100%;
  transform: rotate(180deg);
  position: absolute;
  bottom: -5px;
  z-index: 2;
`;

export const TopBoxWrapper = styled.div`
  margin-top: 50px;
`;

export const ColWrapper = styled.div`
  background-color: #fff;
  padding: 40px 25px;
  border-radius: 5px;
  text-align: center;

  a {
    text-decoration: none;
  }
`;

export const FeatureText = styled.span`
  display: block;
  text-align: center;
`;

export const ContainerThumbnail = styled.img`
  width: 100%;
`;

export const List = styled.ul`
  margin-top: 25px;
`;

export const ListItem = styled.li``;

export const LogoList = styled.img`
  width: 100%;
  margin-top: 35px;
`;

export const HeaderDescriptionWrapper = styled.div`
  text-align: center;
  margin: 0 auto;
  margin-top: 25px;
  max-width: 860px;
`;

export const BottomDescriptionWrapper = styled.div`
  margin-top: 15px;
  margin-bottom: 30px;
`;

export const BottomActionWrapper = styled.div`
  background-color: rgb(0, 116, 166);
  padding-top: 50px;
  padding-bottom: 50px;
  border-radius: 25px;
  a {
    text-decoration: none;
  }
`;

export const LeftSideWrapper = styled.div`
  max-width: 600px;
`;
