import styled from "@emotion/styled";

export const AttentionWrapper = styled.div`
  position: relative;
  background-color: rgb(0, 116, 166);
  padding-top: 70px;
  padding-bottom: 70px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 5px;
  a {
    text-decoration: none;
  }
`;

export const DescriptionWrapper = styled.div`
  margin-top: 15px;
  margin-bottom: 30px;
`;

export const Border = styled.img`
  left: 0px;
  width: 100%;
  transform: rotate(180deg);
  position: absolute;
  bottom: -2.5px;
  z-index: 2;
`;
