import { StyledText } from "./styled";

const Text = ({ color = "dark", variant = "regular", children }) => {
  return (
    <StyledText className={`color-${color} variant-${variant}`}>
      {children}
    </StyledText>
  );
};

export default Text;
