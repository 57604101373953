import styled from "@emotion/styled";

export const Section = styled.section`
  position: relative;
  background-color: #fff;
`;

export const Container = styled.div`
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
  &.dark {
    background-color: #e1ded5;
    z-index: 1;
  }
  &.light {
    background-color: #f5f5ee;
  }
  &.margin-top {
    margin-top: 75px;
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: #e1ded5;
  }
`;

export const ContentWrapper = styled.div`
  max-width: 1300px;
  position: relative;
  margin: 0 auto;
  padding: 0px 15px;
  &.header {
    max-width: 800px;
  }
  &.align-center {
    text-align: center;
  }
`;

export const SectionBorder = styled.img`
  width: 100%;
  transform: rotate(180deg);
  position: absolute;
  bottom: -5px;
  z-index: 2;
`;

export const BackgroundIllustration = styled.img`
  position: absolute;
  bottom: 0px;
  &.left {
    left: 10-px;
  }
  &.right {
    right: -10px;
  }
`;

export const ProfileWrapper = styled.div`
  text-align: center;
  margin-bottom: 25px;
  background-color: #fff;
  padding: 40px 25px;
  border-radius: 5px;
  @media (max-width: 767px) {
    &:last-of-type {
      margin-bottom: 0px;
    }
  }
`;

export const Thumbnail = styled.img`
  width: 100%;
  max-width: 125px;
  max-height: 125px;
  object-fit: contain;
  border-radius: 100%;
`;

export const HeaderDescriptionWrapper = styled.div`
  text-align: center;
  margin: 0 auto;
  margin-top: 25px;
`;

export const BoxDetailsWrapper = styled.div`
  margin-top: 15px;
`;
