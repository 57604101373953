import styled from "@emotion/styled";
import { Navbar, Container, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

export const NavigationBar = styled(Navbar)`
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 100;
  background-color: #e1ded5;
`;

export const NavigationContainer = styled(Container)`
  max-width: 1300px;
  padding: 10px 15px;
`;

export const LogoLink = styled(Link)`
  text-decoration: none;
`;

export const NavigationBrand = styled(Navbar.Brand)`
  font-family: "Poppins", sans-serif;
  font-size: 28px;
  cursor: pointer;
  color: #000;
  font-weight: 500;
  svg {
    width: 190px;
    height: 50px;
    margin-top: -5px;
    margin-right: 5px;
  }
  &:hover {
    color: #000;
  }
`;

export const NavigationToggle = styled(Navbar.Toggle)`
  border: none;
  padding: 0px;
  outline: none !important;
  border: none !important;
  svg {
    outline: none !important;
    fill: #000 !important;
    height: 35px;
    width: 35px;
  }
`;

export const NavigationCollapsed = styled(Navbar.Collapse)``;

export const Logo = styled.img``;

export const Navigation = styled(Nav)`
  @media (min-width: 767px) {
    display: table !important;
    float: left;
    margin-bottom: 5px !important;
  }
  @media (max-width: 776px) {
    display: block !important;
  }
`;

export const NavigationLink = styled(Link)`
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #000;
  text-decoration: none;
  transition: 0.5s;
  &:hover {
    color: #000;
    transform: translateX(5px);
  }
  &.active {
    color: #370d6f !important;
  }
  @media (max-width: 776px) {
    display: block;
    padding: 10px 0px;
  }
  @media (min-width: 767px) {
    margin-right: 15px !important;
  }
`;
