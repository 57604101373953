import {
  Container,
  ContainerThumbnail,
  ContentWrapper,
  ColWrapper,
  LogoList,
  Section,
  SectionBorder,
  TopBoxWrapper,
  HeaderDescriptionWrapper,
  BottomActionWrapper,
  BottomDescriptionWrapper,
  BackgroundIllustration,
  LeftSideWrapper,
} from "./styled";
import Navigation from "../navigation";
import Footer from "../footer";
import separator from "../../assets/images/header-separator.svg";
import topThumbnail from "../../assets/images/frontpage-thumbnail.svg";
import logoList from "../../assets/images/frontpage-logos.png";
import Button from "../library/button";
import Title from "../library/title";
import Text from "../library/text";
import Row from "../library/row";
import Column from "../library/column";
import List from "../library/list";
import AttentionBox from "../library/attention";
import birdIllustration from "../../assets/images/bird.png";
import flowerIllustration from "../../assets/images/flower.png";
import { Link } from "react-router-dom";
const Index = () => {
  const LIST_ITEMS = [
    "Hva er bærekraftig virksomhet?",
    "Hva er ledernes og ledelsens rolle i bærekraftsarbeidet?",
    "Hvordan skape felles forståelse for bærekraft i virksomheten?",
    "Hvordan engasjere og inspirere ledelse og ansatte?",
  ];

  return (
    <Section>
      <Navigation />
      <Container className="margin-top">
        <BackgroundIllustration className="right" src={birdIllustration} />
        <BackgroundIllustration className="left" src={flowerIllustration} />
        <ContentWrapper>
          <Title color="dark" align="center">
            Bærekraftig Ledelse har verktøy for utvikling av bærekraft
          </Title>
          <TopBoxWrapper>
            <Row>
              <Column md={4}>
                <ColWrapper>
                  <Title size="sm">Pulsesjekk Bærekraft</Title>
                  <Text>Hurtigtest av bærekraften</Text>
                  <Link to="/pulssjekk">
                    <Button>Ta testen her</Button>
                  </Link>
                </ColWrapper>
              </Column>
              <Column md={4}>
                <ColWrapper>
                  <Title size="sm">Helsesjekk Bærekraft</Title>
                  <Text>Grundig analyse av bærekraften</Text>
                  <Link to="mailto:knut.ivar@klimapsykologene.no">
                    <Button>Mer informasjon</Button>
                  </Link>
                </ColWrapper>
              </Column>
              <Column md={4}>
                <ColWrapper>
                  <Title size="sm">Bærekraftig Ledelse</Title>
                  <Text>Kurs for ledere og ledergrupper</Text>
                  <Link to="mailto:knut.ivar@klimapsykologene.no">
                    <Button>Mer informasjon</Button>
                  </Link>
                </ColWrapper>
              </Column>
            </Row>
          </TopBoxWrapper>
          <HeaderDescriptionWrapper>
            <Text color="dark">
              Bærekraftig Ledelse er forankret i FNs bærekraftsmål og
              forskningsbasert kunnskap om klima og bærekraft. Vi måler og
              utvikler disse fire dimensjonene: Miljø - Økonomi - Sosialt -
              Styring & Ledelse.
            </Text>
          </HeaderDescriptionWrapper>
        </ContentWrapper>
        <SectionBorder src={separator} />
      </Container>
      <Container className="light">
        <ContentWrapper>
          <Row>
            <Column md={7} className="my-auto">
              <LeftSideWrapper>
                <Title size="sm">
                  Vi har løsninger for de viktigste problemstillingene om
                  bærekraft:
                </Title>
                <List items={LIST_ITEMS} />
                <Text>
                  Prosjektet er et samarbeid mellom Klimapsykologene og forskere
                  fra CICERO, BI, NIBIO og Deakin University.
                </Text>
              </LeftSideWrapper>
            </Column>
            <Column md={5}>
              <ContainerThumbnail src={topThumbnail} />
            </Column>
          </Row>
        </ContentWrapper>
      </Container>
      <Container className="light">
        <ContentWrapper>
          <Title size="lg" color="dark" align="center">
            Vi har gjennomført flere bærekraftsprosjekter for mange ulike
            virksomheter
          </Title>
          <LogoList src={logoList} />
        </ContentWrapper>
      </Container>
      <Container className="light">
        <ContentWrapper className="align-center">
          <AttentionBox />
        </ContentWrapper>
      </Container>
      <Footer />
    </Section>
  );
};

export default Index;
