import {
  ContentWrapper,
  CreditsLabel,
  FooterCol,
  FooterLabel,
  FooterRow,
  Section,
  LogoLink,
  NavigationBrand,
  FooterLink,
  FooterContent,
  Separator,
  KeywordsLabel,
} from "./styled";
import { Link } from "react-router-dom";
import separator from "../../assets/images/bottom-separator.svg";
//import facebookIcon from "../../assets/images/facebook-icon.svg";
//import klimapsykologeneLogo from "../../assets/images/klimapsykologene.svg";
import { ReactComponent as LogoPrimary } from "../../assets/images/logo.svg";
import { ReactComponent as KlimapsykologeneLogo } from "../../assets/images/klimapsykologene.svg";
import { ReactComponent as UnLogo } from "../../assets/images/un-logo.svg";

const Index = () => {
  return (
    <Section>
      <ContentWrapper>
        <KeywordsLabel>
          Miljø - Økonomi - Sosialt - Styring & Ledelse
        </KeywordsLabel>
        <Separator src={separator} />
        <FooterRow>
          <FooterCol md={4} className="my-auto">
            <LogoLink to="/">
              <NavigationBrand>
                <LogoPrimary />
              </NavigationBrand>
            </LogoLink>
          </FooterCol>
          <FooterCol md={4}>
            <FooterContent className="align-center">
              <FooterLabel>
                <FooterLink href="mailto:post@barekraft.org">
                  post@barekraft.org
                </FooterLink>
              </FooterLabel>
              <FooterLabel>
                <FooterLink href="tel:+47 917 07 075">
                  +47 917 07 075
                </FooterLink>
              </FooterLabel>
            </FooterContent>
          </FooterCol>
          <FooterCol md={4}>
            <FooterContent className="align-right">
              <Link to="https://www.klimapsykologene.no/">
                <KlimapsykologeneLogo />
              </Link>
              <Link to="https://unglobalcompact.org/">
                <UnLogo />
              </Link>
            </FooterContent>
          </FooterCol>
        </FooterRow>
        <CreditsLabel>
          Utvikling og design av <Link to="">Andreas Skaalerud</Link>
        </CreditsLabel>
      </ContentWrapper>
    </Section>
  );
};

export default Index;
