import styled from "@emotion/styled";

export const StyledList = styled.ul`
  margin-top: 25px;
`;

export const StyledListItem = styled.li`
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #000;
`;
