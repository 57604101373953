import styled from "@emotion/styled";
import { Col } from "react-bootstrap";

export const StyledColumn = styled(Col)`
  @media (max-width: 767px) {
    margin-bottom: 25px;
    &:last-of-type {
      margin-bottom: 0px;
    }
  }
`;
