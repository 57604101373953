import { StyledList, StyledListItem } from "./styled";

const Index = ({ items = [] }) => {
  return (
    <StyledList>
      {items.map((item) => (
        <StyledListItem>{item}</StyledListItem>
      ))}
    </StyledList>
  );
};

export default Index;
