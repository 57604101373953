import styled from "@emotion/styled";

export const Section = styled.section`
  position: relative;
  background-color: #fff;
`;

export const Container = styled.div`
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
  &.dark {
    background-color: #e1ded5;
    z-index: 1;
  }
  &.light {
    background-color: #f5f5ee;
  }
  &.margin-top {
    margin-top: 75px;
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: #e1ded5;
  }
`;

export const ContentWrapper = styled.div`
  max-width: 1300px;
  position: relative;
  margin: 0 auto;
  padding: 275px 20px;
  &.header {
    max-width: 800px;
  }
  &.align-center {
    text-align: center;
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 35px;
  a {
    text-decoration: none;
  }
`;
