import { StyledColumn } from "./styled";

const Index = ({ children, className, ...props }) => {
  return (
    <StyledColumn className={className} {...props}>
      {children}
    </StyledColumn>
  );
};

export default Index;
