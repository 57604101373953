import { StyledTitle } from "./styled";

const Title = ({ size = "lg", align, color = "dark", children }) => {
  return (
    <StyledTitle className={`color-${color} align-${align} size-${size}`}>
      {children}
    </StyledTitle>
  );
};

export default Title;
