import {
  BackgroundIllustration,
  BottomText,
  ButtonWrapper,
  ColWrapper,
  Container,
  ContentWrapper,
  HeaderDescriptionWrapper,
  IconWrapper,
  InfoWrapper,
  ReportIcon,
  ReportWrapper,
  Section,
  SectionBorder,
} from "./styled";
import Navigation from "../../components/navigation";
import Footer from "../../components/footer";
import separator from "../../assets/images/header-separator.svg";
import Title from "../library/title";
import Text from "../library/text";
import { Link } from "react-router-dom";
import Button from "../library/button";
import Row from "../library/row";
import Column from "../library/column";
import AttentionBox from "../library/attention";
import documentIcon from "../../assets/images/document-icon.svg";
import birdIllustration from "../../assets/images/bird.png";
import flowerIllustration from "../../assets/images/flower.png";

const Index = () => {
  return (
    <Section>
      <Navigation />
      <Container className="margin-top">
        <BackgroundIllustration className="right" src={birdIllustration} />
        <BackgroundIllustration className="left" src={flowerIllustration} />
        <ContentWrapper className="header">
          <Title color="dark" align="center">
            Forskning
          </Title>
          <HeaderDescriptionWrapper>
            <Text color="dark">
              Vi har brukt forskningsbasert kunnskap om bærekraft til å lage
              måleverktøy og lederutvikling. De første verktøyene fokuserte på
              bærekraftig matproduksjon, og er videreutviklet slik at de nå
              passer for alle bransjer og virksomheter. Kunnskapsgrunnlaget for
              Bærekraftig Ledelse er samlet seks rapporter.
            </Text>
          </HeaderDescriptionWrapper>
        </ContentWrapper>
        <SectionBorder src={separator} />
      </Container>
      <Container className="light">
        <ContentWrapper>
          <Row>
            <Column md={4}>
              <ColWrapper>
                <ReportWrapper>
                  <IconWrapper>
                    <ReportIcon src={documentIcon} />
                  </IconWrapper>
                  <InfoWrapper>
                    <Text variant="bold">
                      Hvordan måle virksomheters bærekraft?{" "}
                    </Text>
                  </InfoWrapper>
                </ReportWrapper>
              </ColWrapper>
            </Column>
            <Column md={4}>
              <ColWrapper>
                <ReportWrapper>
                  <IconWrapper>
                    <ReportIcon src={documentIcon} />
                  </IconWrapper>
                  <InfoWrapper>
                    <Text variant="bold">
                      Hva er bærekraftig ledelse og organisering?
                    </Text>
                  </InfoWrapper>
                </ReportWrapper>
              </ColWrapper>
            </Column>
            <Column md={4}>
              <ColWrapper>
                <ReportWrapper>
                  <IconWrapper>
                    <ReportIcon src={documentIcon} />
                  </IconWrapper>
                  <InfoWrapper>
                    <Text variant="bold">
                      Hva er effektiv klimakommunikasjon?
                    </Text>
                  </InfoWrapper>
                </ReportWrapper>
              </ColWrapper>
            </Column>
            <Column md={4}>
              <ColWrapper>
                <ReportWrapper>
                  <IconWrapper>
                    <ReportIcon src={documentIcon} />
                  </IconWrapper>
                  <InfoWrapper>
                    <Text variant="bold">
                      Hva er bærekraftig matproduksjon?
                    </Text>
                  </InfoWrapper>
                </ReportWrapper>
              </ColWrapper>
            </Column>
            <Column md={4}>
              <ColWrapper>
                <ReportWrapper>
                  <IconWrapper>
                    <ReportIcon src={documentIcon} />
                  </IconWrapper>
                  <InfoWrapper>
                    <Text variant="bold">
                      Hva er klimavenlig matproduksjon?
                    </Text>
                  </InfoWrapper>
                </ReportWrapper>
              </ColWrapper>
            </Column>
            <Column md={4}>
              <ColWrapper>
                <ReportWrapper>
                  <IconWrapper>
                    <ReportIcon src={documentIcon} />
                  </IconWrapper>
                  <InfoWrapper>
                    <Text variant="bold">
                      Hva er sammenhengen mellom bærekraft og klima?
                    </Text>
                  </InfoWrapper>
                </ReportWrapper>
              </ColWrapper>
            </Column>
          </Row>
          <BottomText>
            Dersom du ønsker tilgang til rapportene kan du sende oss en
            henvendelse{" "}
            <Link to="mailto:knut.ivar@klimapsykologene.no">her</Link>.
          </BottomText>
        </ContentWrapper>
      </Container>
      <Container className="light">
        <ContentWrapper className="align-center">
          <AttentionBox />
        </ContentWrapper>
      </Container>
      <Footer />
    </Section>
  );
};

export default Index;
