import { ButtonWrapper, Container, ContentWrapper, Section } from "./styled";
import Navigation from "../navigation";
import Footer from "../footer";
import Title from "../library/title";
import Text from "../library/text";
import { Link } from "react-router-dom";
import Button from "../library/button";

const NotFound = () => {
  return (
    <Section>
      <Navigation />
      <Container className="light">
        <ContentWrapper className="header align-center">
          <Title>Siden eksisterer ikke</Title>
          <Text>
            Siden du prøver å gå til har enten blitt flyttet eller eksisterer
            ikke lenger.
          </Text>
          <ButtonWrapper>
            <Link to="/">
              <Button>Gå til forsiden</Button>
            </Link>
          </ButtonWrapper>
        </ContentWrapper>
      </Container>
      <Footer />
    </Section>
  );
};

export default NotFound;
