import { Link } from "react-router-dom";
import Title from "../title";
import Text from "../text";
import Button from "../button";
import { AttentionWrapper, Border, DescriptionWrapper } from "./styled";
import separator from "../../../assets/images/blue-separator.svg";

const Index = () => {
  return (
    <AttentionWrapper>
      <Title color="light" align="center">
        Interessert i å vite mer?
      </Title>
      <DescriptionWrapper>
        <Text color="light">
          Få tilsendt et uforpliktende tilbud om bærekraft til din virksomhet.
        </Text>
      </DescriptionWrapper>
      <Link to="mailto:knut.ivar@klimapsykologene.no">
        <Button color="light">Få tilbud om bærekraft</Button>
      </Link>
      <Border src={separator} />
    </AttentionWrapper>
  );
};

export default Index;
