import styled from "@emotion/styled";

export const StyledText = styled.span`
  color: #000;
  display: block;
  line-height: 1.6rem;
  font-size: 18px;
  font-weight: 600;
  font-family: "Raleway", sans-serif;
  &.variant-bold {
    font-family: "Chewy", cursive;
    font-size: 20px;
  }
  &.color-light {
    color: #fff;
  }
`;
