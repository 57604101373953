import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Frontpage from "./components/frontpage";
import Tools from "./components/tools";
import Partners from "./components/partners";
import Research from "./components/research";
import NotFound from "./components/not-found";
import "bootstrap/dist/css/bootstrap.min.css";
import PulseCheck from "./components/pulse-check";
const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Frontpage />} />
        <Route exact path="/verktøy" element={<Tools />} />
        <Route exact path="/partnere" element={<Partners />} />
        <Route exact path="/forskning" element={<Research />} />
        <Route exact path="/pulssjekk" element={<PulseCheck />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
