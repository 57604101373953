import styled from "@emotion/styled";
import { Col, Row, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

export const Section = styled.section`
  position: relative;
  background-color: #f5f5ee;
`;

export const ContentWrapper = styled.div`
  padding: 0px 50px 20px 50px;
  position: relative;
  max-width: 1400px;
  margin: 0 auto;
`;

export const Separator = styled.img`
  width: 100%;
  margin-bottom: 50px;
  margin-top: 50px;
`;

export const FooterRow = styled(Row)``;

export const FooterCol = styled(Col)`
  margin-bottom: 25px;
  @media (max-width: 767px) {
    text-align: center;
    margin-bottom: 0px;
    &:last-of-type {
      margin-bottom: 0px;
    }
  }
`;

export const FooterTitle = styled.h5`
  font-family: "Poppins", sans-serif;
  color: grey;
`;

export const FooterContent = styled.div`
  margin-top: 15px;
  &.align-center {
    text-align: center;
  }
  &.align-right {
    text-align: right;
    svg {
      max-width: 190px;
      height: 70px;
    }
  }
  @media (max-width: 767px) {
    text-align: center !important;
  }
`;

export const FooterLabel = styled.span`
  color: grey;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  display: block;
  line-height: 1.8rem;
  font-size: 18px;
`;

export const FooterLink = styled.a`
  color: #0074a6 !important;
`;

export const CreditsLabel = styled.span`
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  color: grey;
  font-size: 14px;
  margin: 0 auto;
  display: block;
  text-align: center;
  margin-top: 35px;
  a {
    color: #0074a6;
  }
`;

export const LogoLink = styled(Link)`
  text-decoration: none;
`;

export const NavigationBrand = styled(Navbar.Brand)`
  font-family: "Poppins", sans-serif;
  font-size: 28px;
  cursor: pointer;
  color: #5a9572;
  font-weight: 500;
  svg {
    width: 250px;
    height: 50px;
    margin-top: -5px;
    margin-right: 5px;
  }
  &:hover {
    color: #5a9572;
  }
`;

export const SocialIcon = styled.img`
  height: 45px;
`;

export const Logo = styled.img`
  display: block;
  width: 100%;
  max-width: 150px;
  margin-left: -10px;
  margin-top: 5px;
`;

export const KeywordsLabel = styled.span`
  margin: 0 auto;
  display: block;
  position: relative;
  width: 100%;
  text-align: center;
  font-family: "Chewy", cursive;
  font-weight: 600;
  font-size: 20px;
`;

export const AssociationWrapper = styled.div`
  max-width: 250px;
  margin-top: -10px;
  svg {
    width: 100%;
  }
`;
