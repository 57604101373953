import styled from "@emotion/styled";

export const StyledTitle = styled.h1`
  font-family: "Chewy", cursive;
  font-weight: 600;
  &.color-dark {
    color: #000;
  }
  &.color-light {
    color: #fff;
  }
  &.align-center {
    text-align: center;
    max-width: 780px;
    margin: 0 auto;
  }
  &.size-lg {
    font-size: 48px;
  }
  &.size-md {
    font-size: 38px;
  }
  &.size-sm {
    font-size: 30px;
  }
  &.wide {
    max-width: 1100px;
  }
  @media (max-width: 767px) {
    font-size: 36px;
    &.below-picture {
      margin-top: 25px;
    }
    &.size-lg {
      font-size: 40px;
    }
    &.size-md {
      font-size: 30px;
    }
    &.size-sm {
      font-size: 22px;
    }
  }
`;
