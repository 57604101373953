import {
  BackgroundIllustration,
  BoxWrapper,
  ColWrapper,
  Container,
  ContentWrapper,
  HeaderDescriptionWrapper,
  Section,
  SectionBorder,
} from "./styled";
import Navigation from "../navigation";
import Footer from "../footer";
import separator from "../../assets/images/header-separator.svg";
import Button from "../library/button";
import Title from "../library/title";
import Text from "../library/text";
import Column from "../library/column";
import Row from "../library/row";
import AttentionBox from "../library/attention";
import birdIllustration from "../../assets/images/bird.png";
import flowerIllustration from "../../assets/images/flower.png";

const Index = () => {
  return (
    <Section>
      <Navigation />
      <Container className="margin-top">
        <BackgroundIllustration className="right" src={birdIllustration} />
        <BackgroundIllustration className="left" src={flowerIllustration} />
        <ContentWrapper className="header">
          <Title color="dark" align="center">
            Praktiske verktøy for bedre bærekraft
          </Title>
          <HeaderDescriptionWrapper>
            <Text color="dark">
              Verktøyene hjelper lederne til å bedømme og forsterke
              virksomhetens bærekraft. Alle verktøyene basert på
              forskningsbasert kunnskap, er fleksible og kan tilpasses
              situasjonen i virksomheten.
            </Text>
          </HeaderDescriptionWrapper>
        </ContentWrapper>
        <SectionBorder src={separator} />
      </Container>
      <Container className="light">
        <ContentWrapper>
          <Title color="dark" align="center">
            Bærekraftsmåling
          </Title>
          <BoxWrapper>
            <Row>
              <Column md={4}>
                <ColWrapper>
                  <Title size="sm">Pulsesjekk bærekraft</Title>
                  <Text>
                    Rask test som gir et hovedbilde av virksomhetens bærekraft
                  </Text>
                  <Button>Få mer informasjon</Button>
                </ColWrapper>
              </Column>
              <Column md={4}>
                <ColWrapper>
                  <Title size="sm">Helsesjekk bærekraft</Title>
                  <Text>
                    Grundig analyse som gir et faktabasert kart over
                    virksomhetens bærekraft
                  </Text>
                  <Button>Få mer informasjon</Button>
                </ColWrapper>
              </Column>
              <Column md={4}>
                <ColWrapper>
                  <Title size="sm">Nudgesjekk klima</Title>
                  <Text>
                    Analyse av hvordan virksomheten påvirker kundene til å velge
                    klimavennlig
                  </Text>
                  <Button>Få mer informasjon</Button>
                </ColWrapper>
              </Column>
            </Row>
          </BoxWrapper>
        </ContentWrapper>
      </Container>
      <Container className="light">
        <ContentWrapper>
          <Title color="dark" align="center">
            Virksomhetsutvikling
          </Title>
          <BoxWrapper>
            <Row>
              <Column md={6}>
                <ColWrapper>
                  <Title size="sm">Bærekraftig lederutvikling</Title>
                  <Text>Utviklingsprogram for ledere og ledergrupper</Text>
                  <Button>Få mer informasjon</Button>
                </ColWrapper>
              </Column>
              <Column md={6}>
                <ColWrapper>
                  <Title size="sm">
                    Bærekraftstrategi og plan for bærekraft
                  </Title>
                  <Text>Hvordan komme fra ord til handling</Text>
                  <Button>Få mer informasjon</Button>
                </ColWrapper>
              </Column>
              <Column md={6}>
                <ColWrapper>
                  <Title size="sm">Bærekraftskompetanse</Title>
                  <Text>Temadager og workshops om bærekraft i praksis</Text>
                  <Button>Få mer informasjon</Button>
                </ColWrapper>
              </Column>
              <Column md={6}>
                <ColWrapper>
                  <Title size="sm">Klimakommunikasjon</Title>
                  <Text>Hvordan påvirke kundene til å velge klimavennlig</Text>
                  <Button>Få mer informasjon</Button>
                </ColWrapper>
              </Column>
            </Row>
          </BoxWrapper>
        </ContentWrapper>
      </Container>
      <Container className="light">
        <ContentWrapper className="align-center">
          <AttentionBox />
        </ContentWrapper>
      </Container>
      <Footer />
    </Section>
  );
};

export default Index;
