import {
  BackgroundIllustration,
  BoxDetailsWrapper,
  Container,
  ContentWrapper,
  HeaderDescriptionWrapper,
  ProfileWrapper,
  Section,
  SectionBorder,
  Thumbnail,
} from "./styled";
import separator from "../../assets/images/header-separator.svg";
import Navigation from "../navigation";
import Footer from "../footer";
import { Link } from "react-router-dom";
import photoKnutIvar from "../../assets/images/photo-knut-ivar.svg";
import photoPerEspen from "../../assets/images/photo-per-espen.svg";
import photoHanne from "../../assets/images/photo-hanne-refsholt.svg";
import photoArne from "../../assets/images/photo-arne-bardalen.svg";
import photoBob from "../../assets/images/photo-bob-van-oort.svg";
import photoFelice from "../../assets/images/photo-felice-jacka.svg";
import Button from "../library/button";
import Title from "../library/title";
import Text from "../library/text";
import Row from "../library/row";
import Column from "../library/column";
import AttentionBox from "../library/attention";
import birdIllustration from "../../assets/images/bird.png";
import flowerIllustration from "../../assets/images/flower.png";

const Index = () => {
  return (
    <Section>
      <Navigation />
      <Container className="margin-top">
        <BackgroundIllustration className="right" src={birdIllustration} />
        <BackgroundIllustration className="left" src={flowerIllustration} />
        <ContentWrapper className="header">
          <Title color="dark" align="center">
            Partnere
          </Title>
          <HeaderDescriptionWrapper>
            <Text color="dark">
              Bærekraftig ledelse er et samarbeid mellom Institutt for
              Klimapsykologi, forskere fra CICERO, NIBIO, Universitetet i Oslo
              og Handelshøyskolen BI.
            </Text>
          </HeaderDescriptionWrapper>
        </ContentWrapper>
        <SectionBorder src={separator} />
      </Container>
      <Container className="light">
        <ContentWrapper>
          <Row>
            <Column md={4}>
              <ProfileWrapper>
                <Thumbnail src={photoKnutIvar} />
                <BoxDetailsWrapper>
                  <Title size="sm">Knut Ivar Karevold</Title>
                  <Text>Klimapsykologene</Text>
                  <Button>Send e-post</Button>
                </BoxDetailsWrapper>
              </ProfileWrapper>
            </Column>
            <Column md={4}>
              <ProfileWrapper>
                <Thumbnail src={photoHanne} />
                <BoxDetailsWrapper>
                  <Title size="sm">Hanne Refsholt</Title>
                  <Text>Direktør</Text>
                  <Button>Send e-post</Button>
                </BoxDetailsWrapper>
              </ProfileWrapper>
            </Column>
            <Column md={4}>
              <ProfileWrapper>
                <Thumbnail src={photoPerEspen} />
                <BoxDetailsWrapper>
                  <Title size="sm">Per Espen Stoknes</Title>
                  <Text>Klimapsykologene</Text>
                  <Button>Send e-post</Button>
                </BoxDetailsWrapper>
              </ProfileWrapper>
            </Column>
            <Column md={4}>
              <ProfileWrapper>
                <Thumbnail src={photoArne} />
                <BoxDetailsWrapper>
                  <Title size="sm">Arne Bardalen</Title>
                  <Text>NIBIO</Text>
                  <Button>Send e-post</Button>
                </BoxDetailsWrapper>
              </ProfileWrapper>
            </Column>
            <Column md={4}>
              <ProfileWrapper>
                <Thumbnail src={photoBob} />
                <BoxDetailsWrapper>
                  <Title size="sm">Bob Van Oort</Title>
                  <Text>CICERO</Text>
                  <Button>Send e-post</Button>
                </BoxDetailsWrapper>
              </ProfileWrapper>
            </Column>
            <Column md={4}>
              <ProfileWrapper>
                <Thumbnail src={photoFelice} />
                <BoxDetailsWrapper>
                  <Title size="sm">Felice Jacka</Title>
                  <Text>Deakin University</Text>
                  <Button>Send e-post</Button>
                </BoxDetailsWrapper>
              </ProfileWrapper>
            </Column>
          </Row>
        </ContentWrapper>
      </Container>
      <Container className="light">
        <ContentWrapper className="align-center">
          <AttentionBox />
        </ContentWrapper>
      </Container>
      <Footer />
    </Section>
  );
};

export default Index;
