import styled from "@emotion/styled";

export const Section = styled.section`
  position: relative;
  background-color: #fff;
`;

export const Container = styled.div`
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
  &.dark {
    background-color: #e1ded5;
    z-index: 1;
  }
  &.light {
    background-color: #f5f5ee;
  }
  &.margin-top {
    margin-top: 75px;
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: #e1ded5;
  }
`;

export const ContentWrapper = styled.div`
  max-width: 1300px;
  position: relative;
  margin: 0 auto;
  padding: 0px 15px;
  &.header {
    max-width: 800px;
  }
  &.align-center {
    text-align: center;
  }
`;

export const SectionBorder = styled.img`
  width: 100%;
  transform: rotate(180deg);
  position: absolute;
  bottom: -5px;
  z-index: 2;
`;

export const BackgroundIllustration = styled.img`
  position: absolute;
  bottom: 0px;
  &.left {
    left: 10-px;
  }
  &.right {
    right: -10px;
  }
`;

export const ColWrapper = styled.div`
  padding: 40px 25px;
  border-radius: 12.5px;
`;

export const ReportWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`;

export const IconWrapper = styled.div`
  flex: 10%;
`;

export const InfoWrapper = styled.div`
  flex: 65%;
`;

export const ButtonWrapper = styled.div`
  flex: 35%;
`;

export const HeaderDescriptionWrapper = styled.div`
  text-align: center;
  margin: 0 auto;
  margin-top: 25px;
`;

export const BottomText = styled.span`
  text-align: center;
  margin: 0 auto;
  width: 100%;
  display: block;
  margin-top: 30px;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 18px;
  a {
    color: rgb(0, 116, 166);
  }
`;

export const ReportIcon = styled.img`
  width: 100%;
`;
