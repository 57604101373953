import {
  LogoLink,
  Navigation,
  NavigationBar,
  NavigationBrand,
  NavigationCollapsed,
  NavigationContainer,
  NavigationLink,
  NavigationToggle,
} from "./styled";
import { ReactComponent as Menu } from "../../assets/images/menu.svg";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";

const Index = () => {
  return (
    <NavigationBar expand="md" collapseOnSelect>
      <NavigationContainer>
        <LogoLink to="/">
          <NavigationBrand>
            <Logo />
          </NavigationBrand>
        </LogoLink>
        <NavigationToggle id="navToggle" aria-controls="responsiveNav">
          <Menu />
        </NavigationToggle>
        <NavigationCollapsed id="responsiveNav">
          <Navigation className="ms-auto">
            <NavigationLink to="/">Hjem</NavigationLink>
            <NavigationLink to="/pulssjekk">Pulssjekk</NavigationLink>
            <NavigationLink to="/verktøy">Verktøy</NavigationLink>
            <NavigationLink to="/partnere">Partnere</NavigationLink>
            <NavigationLink to="/forskning">Forskning</NavigationLink>
          </Navigation>
        </NavigationCollapsed>
      </NavigationContainer>
    </NavigationBar>
  );
};

export default Index;
