import Navigation from "../../components/navigation";
import Footer from "../../components/footer";
import { Widget } from "@typeform/embed-react";
import { EmbeddedWrapper } from "./styled";

const PulseCheck = () => {
  return (
    <>
      <Navigation />
      <EmbeddedWrapper>
        <Widget
          id="iqysQIEG"
          style={{ width: "100%", height: "100vh" }}
          className="my-form"
        />
      </EmbeddedWrapper>

      <Footer />
    </>
  );
};

export default PulseCheck;
